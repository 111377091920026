import { useWeb3Actions } from "@/web3/context";
import { useCallback } from "react";
import { logout } from "@/api/auth";
import { setLocalStorage } from "@/app/(components)/ThirdParty/helpers";

export default function useLogout(onLogout: () => void) {
  const { disconnect } = useWeb3Actions();

  return useCallback(async () => {
    await logout();
    setLocalStorage("current-user", null);
    disconnect();
    onLogout();
  }, [onLogout, disconnect]);
}
