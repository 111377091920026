"use client";
import React from "react";
import { Theme } from "@/shared/constants";
import NotificationButton from "@/app/(components)/Application/Notifications/NotificationButton";
import Navigation from "../Navigation/Navigation";
import MobileNavigation from "../MobileSidebar/MobileSidebar";
import Link from "next/link";
import ArtworkSubItemImage from "@/assets/graphics/artwork.svg";
import ArtistsSubItemImage from "@/assets/graphics/artists.svg";
import CollectionsSubItemImage from "@/assets/graphics/collections.svg";
import ForumSubItemImage from "@/assets/graphics/forum.svg";
import ArtFeedSubItemImage from "@/assets/graphics/art-feed.svg";
// import RankingsSubItemImage from "@/assets/graphics/rankings.svg";
import ActivitySubItemImage from "@/assets/graphics/activity.svg";
import CollectivesSubItemImage from "@/assets/graphics/collectives.svg";
import AllPostsSubItemImage from "@/assets/graphics/all-posts.svg";
import NewsSubItemImage from "@/assets/graphics/news.svg";
import KnowHowSubItemImage from "@/assets/graphics/know-how.svg";
import ArtAndArtistsSubItemImage from "@/assets/graphics/art-and-artists.svg";
import config from "@shared/config/client";

import Logo from "../../Basic/Logo/Logo";
import { Button } from "../../Basic/Button";
import { ThemedIcon } from "../../Basic/Icon";
import UserDropdown from "../../Application/User/Dropdown/UserDropdown";
import { POST_CATEGORY_SLUG, PostCategoryType } from "@/api/contentful/types";
import { PORTAL_ROUTE, getCategoryRoute } from "@/app/media/constants";
import useMe from "@/shared/hooks/useMe";
import { NavigationMenuItem } from "../Navigation";
import { useQuery } from "@tanstack/react-query";
import lastSeenArtOrderApi from "@/api/lastSeenArtOrder/client";
import NotificationIndicator from "../../Application/Notifications/NotificationIndicator";
import { isUserVerifiedArtist } from "@/api/users/helpers";
import { UserRoles } from "@/api/users/types";
import {
  createNavigationItem,
  createNavigationSeparatorItem,
  createNavigationSubItem,
} from "../Navigation/helpers";

export interface Props {
  theme?: Theme;
}

export function HeaderContent({ theme }: Props) {
  const { me } = useMe();

  const notProductionReadyMenuItems: string[] = [];

  const { data: unseenOrdersExist } = useQuery({
    queryKey: ["unseen-orders-exist", me?.id],
    queryFn: () => lastSeenArtOrderApi.checkIfUnseenOrdersExist(),
    enabled:
      !!me &&
      isUserVerifiedArtist(me) &&
      (config.webEnv !== "production" ||
        !notProductionReadyMenuItems.includes("/art-orders")),
  });

  const menuItems: NavigationMenuItem[] = [
    createNavigationItem({
      name: "Explore",
      href: "#",
      subMenu: [
        createNavigationSubItem({
          name: "Artwork",
          title: "Artwork",
          description: "Explore hundreds of unique artworks on our platform.",
          href: "/artwork",
          image: <ArtworkSubItemImage className="h-full" />,
          iconNameOnMobile: "artwork-mobile",
        }),
        createNavigationSubItem({
          name: "Artists",
          title: "Artists",
          description:
            "Our hand-picked artists are the heartbeat of Endemic marketplace.",
          href: "/artists",
          image: <ArtistsSubItemImage className="h-full" />,
          iconNameOnMobile: "artists-mobile",
        }),
        createNavigationSubItem({
          name: "Collections",
          title: "Collections",
          description: "Discover rare and unique collections.",
          href: "/collections",
          image: <CollectionsSubItemImage className="h-full" />,
          iconNameOnMobile: "collections-mobile",
        }),
        createNavigationSubItem({
          name: "Activity",
          title: "Activity",
          description: "Check out what the artists you follow have been up to.",
          href: "/activities",
          image: <ActivitySubItemImage className="h-full" />,
          iconNameOnMobile: "activity-mobile",
        }),
        createNavigationSubItem({
          name: "Collectives",
          title: "Collectives",
          description: "Discover groups of artists.",
          href: "/collectives",
          image: <CollectivesSubItemImage className="h-full" />,
          iconNameOnMobile: "collectives-mobile",
        }),
        // {
        //   name: "Rankings",
        //   title: "Rankings",
        //   description: "Stay updated on the highest-rated art and artists.",
        //   href: "/rankings",
        //   image: <RankingsSubItemImage className="h-full -scale-x-100" />,
        //   iconNameOnMobile: "rankings-mobile",
        // },
      ],
    }),
    ...(!!me && me?.role !== UserRoles.ARTIST
      ? [
          createNavigationItem({
            href: "/apply/artist",
            name: "Apply to mint",
          }),
        ]
      : []),
    createNavigationItem({
      name: (
        <div className="lg:text-center relative">
          {"Art Orders"}
          {unseenOrdersExist && (
            <NotificationIndicator
              size="small"
              className="absolute lg:right-[-8px] right-[140px] top-0"
            />
          )}
        </div>
      ),
      href: "/art-orders",
    }),
    createNavigationItem({
      name: "Socials",
      href: "#",
      subMenu: [
        createNavigationSubItem({
          name: "Art Feed",
          title: "Art Feed",
          description:
            "Keep up with the latest art, news, and updates from the marketplace.",
          href: "/feed",
          image: <ArtFeedSubItemImage className="h-full" />,
          iconNameOnMobile: "art-and-artists-mobile",
        }),
        createNavigationSubItem({
          name: "Forum",
          title: "Forum",
          description:
            "Connect, discuss, and share ideas with other art enthusiasts.",
          href: "/forum",
          image: <ForumSubItemImage className="h-full" />,
          iconNameOnMobile: "all-posts-mobile",
        }),
        createNavigationSeparatorItem({
          title: "Media",
        }),
        createNavigationSubItem({
          name: "All posts",
          title: "All posts",
          description: "Check out posts spanning all categories.",
          href: PORTAL_ROUTE,
          image: <AllPostsSubItemImage className="h-full" />,
          iconNameOnMobile: "all-posts-mobile",
        }),
        createNavigationSubItem({
          name: PostCategoryType.News,
          title: "News",
          description: "Dive into the latest happenings in the NFT world.",
          href: getCategoryRoute(POST_CATEGORY_SLUG[PostCategoryType.News]),
          image: <NewsSubItemImage className="h-full" />,
          iconNameOnMobile: "news-mobile",
        }),
        createNavigationSubItem({
          name: PostCategoryType.ArtAndArtists,
          title: "Art & Artists",
          description:
            "Uncover the creative side of art and artists in our digital real.",
          href: getCategoryRoute(
            POST_CATEGORY_SLUG[PostCategoryType.ArtAndArtists],
          ),
          image: <ArtAndArtistsSubItemImage className="h-full" />,
          iconNameOnMobile: "art-and-artists-mobile",
        }),
        createNavigationSubItem({
          name: PostCategoryType.KnowHow,
          title: "Know-how",
          description:
            "Discover valuable insights and knowledge in the digital landscape.",
          href: getCategoryRoute(POST_CATEGORY_SLUG[PostCategoryType.KnowHow]),
          image: <KnowHowSubItemImage className="h-full" />,
          iconNameOnMobile: "know-how-mobile",
        }),
      ],
    }),
    createNavigationItem({
      name: "AI Search",
      href: "/search",
    }),
    createNavigationItem({
      name: "Mint now",
      href: config.creatorStudioUrl,
    }),
    ...(config.tokenPageUrl
      ? [
          createNavigationItem({
            name: "Airdrop (LIVE)",
            href: "/quests/uncompleted",
            className: "text-endemic gradient font-black text-[20px]",
          }),
        ]
      : []),
  ];

  const menuItemsCalculated = menuItems.filter(
    (item) =>
      config.webEnv !== "production" ||
      !notProductionReadyMenuItems.includes(item.href || ""),
  );
  return (
    <div>
      <div className="flex items-center justify-between xl:px-6 px-4 py-3">
        <div className="flex">
          <div className="flex items-center xl:w-auto  w-full">
            <Link href="/" className="relative">
              <Logo alt="Logo" className="w-32 h-5 xl:hidden" />
              <ThemedIcon
                alt="Logo"
                iconName="endy"
                className={"hidden xl:flex xl:h-9 w-10 h-5 xl:mr-6"}
              />
            </Link>
          </div>
          <Navigation
            menuItems={menuItemsCalculated}
            shouldHideActiveIndicatorOnScroll={true}
          />
        </div>

        <div className="flex items-center gap-x-3 ml-auto h-10 xl:hidden xl:h-auto ">
          {me ? (
            <div className="flex gap-3 items-center">
              <UserDropdown user={me} small />
              <NotificationButton small />
            </div>
          ) : (
            <Button
              className="whitespace-nowrap h-8 mt-1 text-base"
              href={"/login"}
            >
              {"Log in"}
            </Button>
          )}
          <MobileNavigation theme={theme} menuItems={menuItemsCalculated} />
        </div>

        <div className="hidden xl:flex xl:gap-x-5 xl:justify-end items-center">
          {me ? (
            <UserDropdown user={me} />
          ) : (
            <Button
              className="md:night:hover:bg-yellow"
              href={"/login"}
              startIcon={<ThemedIcon className="w-6 h-6" iconName="endy" />}
            >
              {"Log in"}
            </Button>
          )}
          {me && <NotificationButton />}
        </div>
      </div>
    </div>
  );
}

export default HeaderContent;
