"use client";
import { useRouter } from "next/navigation";
import Script from "next/script";
import React, { useCallback, useEffect } from "react";
import config from "@shared/config/client";

enum ChatbotEvent {
  CLICK = "click:chatlink",
}

const Chatbot = () => {
  const router = useRouter();
  const handleMessage = useCallback((event: MessageEvent) => {
    if (event.origin !== "https://chat.codeasy.com") return;
    switch (event.data.event) {
      case ChatbotEvent.CLICK:
        if (event.data.url.startsWith(config.url)) {
          router.push(event.data.url);
        }
        break;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleMessage, false);
    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, []);

  return (
    <div>
      <Script
        src="https://chat.codeasy.com/embed.js?id=cm3rx1o1t0003s0rftuvkume3&buttonColor=#D61C5C&right=20&bottom=20&height=500&width=400&locale=en"
        strategy="afterInteractive"
      />
    </div>
  );
};

export default Chatbot;
