"use client";
import React from "react";
import { twMerge } from "tailwind-merge";
import { NavigationItem } from "./NavigationItem";
import styles from "./Navigation.module.css";
import { NavigationMenuItem } from "./types";

export interface Props {
  menuItems: NavigationMenuItem[];
  minimizeOnScroll?: boolean;
  shouldHideActiveIndicatorOnScroll?: boolean;
  className?: string;
  itemClassName?: string;
  preserveSearchQuery?: boolean;
}

function Navigation({
  menuItems,
  shouldHideActiveIndicatorOnScroll = true,
  minimizeOnScroll,
  className,
  itemClassName,
  preserveSearchQuery = false,
}: Props) {
  return (
    <div
      className={twMerge(
        !minimizeOnScroll
          ? "hidden justify-center w-full xl:flex xl:w-fit gap-x-6 text-center font-center items-center"
          : `${styles.scrollbarHide} flex gap-x-10 overflow-x-scroll overflow-y-hidden lg:pb-[100px] p-3 pt-0 pb-20`,
        className,
      )}
    >
      {menuItems.map((menuItem: NavigationMenuItem, index: number) => {
        const navigationMenuItem = menuItem as NavigationMenuItem;
        return (
          <NavigationItem
            menuItem={navigationMenuItem}
            key={index}
            shouldHideActiveIndicatorOnScroll={
              shouldHideActiveIndicatorOnScroll
            }
            className={itemClassName}
            preserveSearchQuery={preserveSearchQuery}
          />
        );
      })}
    </div>
  );
}

export default Navigation;
